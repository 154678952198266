import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { VideoButtonWrapper } from "./styles";
import { Text } from "@website-builder/ui/shared/elements";

const VideoButton = ({
	className,
	buttonText = "",
	isDarkBg = false,
	onClick = () => {},
	disabled,
	...restProps
}) => {
	// checks for mobile width
	const satisfiesMobileWidth = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);
	const [isMobile, setIsMobile] = useState(undefined);

	useEffect(() => {
		setIsMobile(satisfiesMobileWidth);
	}, [satisfiesMobileWidth]);

	return (
		<VideoButtonWrapper
			className={className}
			isDarkBg={isDarkBg}
			onClick={onClick}
			aria-label="Video Button"
			aria-haspopup="dialog"
			disabled={disabled}
		>
			<svg
				width={isMobile ? "48" : "80"}
				height={isMobile ? "48" : "80"}
				viewBox="0 0 80 80"
				fill="none"
				alt=""
				xmlns="http://www.w3.org/2000/svg"
				{...restProps}
			>
				<circle cx="40" cy="40" r="39" stroke="#008E67" strokeWidth="2" />
				<path
					d="M50.6037 39.1467C51.2418 39.5366 51.2418 40.4634 50.6037 40.8533L35.5214 50.0702C34.8551 50.4774 34 49.9979 34 49.2169L34 30.7831C34 30.0021 34.8551 29.5226 35.5215 29.9298L50.6037 39.1467Z"
					fill="#008E67"
				/>
			</svg>
			{buttonText && <Text variant="label_L">{buttonText}</Text>}
		</VideoButtonWrapper>
	);
};

VideoButton.propTypes = {
	isDarkBg: PropTypes.bool,
	buttonText: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default VideoButton;
