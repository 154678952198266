import styled from "styled-components";

export const VideoButtonWrapper = styled.button`
	padding: 0;
	margin: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 1rem;
	background: transparent;
	border: none;
	box-shadow: none;
	color: ${({ isDarkBg }) =>
		isDarkBg ? "var(--primary-green-400)" : "var(--primary-forest-400)"};
	&:hover {
		& circle {
			fill: ${({ isDarkBg }) =>
				isDarkBg ? "var(--primary-green-400)" : "var(--primary-green-600)"};
			fill-opacity: ${({ isDarkBg }) => (isDarkBg ? "0.4" : "0.25")};
		}
	}
	& circle {
		stroke: ${({ isDarkBg }) =>
			isDarkBg ? "var(--primary-green-400)" : "var(--primary-forest-400)"};
	}
	& path {
		fill: ${({ isDarkBg }) =>
			isDarkBg ? "var(--primary-green-400)" : "var(--primary-forest-400)"};
	}
`;
